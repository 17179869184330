<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1 p-0 m-0">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          กล่องสุ่ม{{ isActive === 1 ? "2ตัวท้าย" : "สลาก" }}
        </h3>
      </div>

      <!-- Box select 2b -->
      <div v-if="isActive === 1">
        <div class="row justify-content-center my-2">
          <div class="col-md-4 col-6 p-0">

            <!-- <div
              v-if="getLast !== 1"
              class="post-tail"
            >
              <p class="mb-0 text-white ">
                สุดคุ้ม
              </p>
              <h4 class="mb-0 mt-n25 text-white ">
                ลด {{ getLast === 10 ? '20%' : getLast === 5 ? '10%' : getLast === 2 ? '5%' : '' }}
              </h4>
            </div> -->

            <div class="card-box-xl">
              <img
                v-if="getLast !== 1"
                :src="`/images/sale/sale-${
                  getLast === 10
                    ? '20'
                    : getLast === 5
                      ? '10'
                      : getLast === 2
                        ? '5'
                        : ''
                }.svg`"
                alt=""
                class="img-sale"
              >
              <img
                :src="`/images/box/box-${getLast}-lg.png`"
                alt=""
                class="img-fluid img-box"
              >
              <!-- <p class="x-poster">
                {{ getLast }}
              </p> -->

              <div class="point-btm">
                ได้รับ
                {{
                  getLast === 10
                    ? "160"
                    : getLast === 5
                      ? "70"
                      : getLast === 2
                        ? "20"
                        : getLast === 1
                          ? "5"
                          : ""
                }}
                พ้อยท์
              </div>
            </div>
          </div>
        </div>

        <div class="txt-mas">
          <div class="img-table">
            <img
              src="@/assets/images/newIcon/t-point.png"
              alt="t-point"
              class="img-fluid"
            >
          </div>

          <div class="row my-1">
            <div class="col-3 pl-50 pr-50 cx-box">
              <p class="x-poster m-0">
                10ชุด
              </p>

              <div
                class="card-box"
                :class="{ actived: getLast === 10 }"
                @click="(getLast = 10), (type = 0)"
              >
                <img
                  src="/images/sale/20per.svg"
                  alt="20per"
                  class="img-xs-sale"
                >

                <div :class="`${getLast === 10 ? 'p-75' : 'p-1'}`">
                  <img
                    src="/images/box/box-10.png"
                    alt="box"
                    class="img-fluid"
                  >
                </div>

                <p class="mb-0 text-primary">
                  กล่องละ 240,000w
                </p>
              </div>
            </div>

            <div class="col-3 pl-50 pr-50 cx-box">
              <p class="x-poster m-0">
                5ชุด
              </p>

              <div
                class="card-box"
                :class="{ actived: getLast === 5 }"
                @click="(getLast = 5), (type = 0)"
              >
                <img
                  src="/images/sale/10per.svg"
                  alt="10per"
                  class="img-xs-sale"
                >
                <!-- <div class="post-tail d-flex justify-content-center">
                  <p class="mb-0 mt-n25 text-white ">
                    -10%
                  </p>
                </div> -->

                <div :class="`${getLast === 5 ? 'p-75' : 'p-1'}`">
                  <img
                    src="/images/box/box-5.png"
                    alt="box"
                    class="img-fluid"
                  >
                </div>

                <p class="mb-0 text-primary">
                  กล่องละ 135,000w
                </p>
              </div>
            </div>

            <div class="col-3 pl-50 pr-50 cx-box">
              <p class="x-poster m-0">
                2ชุด
              </p>

              <div
                class="card-box"
                :class="{ actived: getLast === 2 }"
                @click="(getLast = 2), (type = 1)"
              >
                <img
                  src="/images/sale/5per.svg"
                  alt="5per"
                  class="img-xs-sale"
                >
                <!-- <div class="post-tail d-flex justify-content-center">
                  <p class="mb-0 mt-n25 text-white ">
                    -5%
                  </p>
                </div> -->

                <div :class="`${getLast === 2 ? 'p-75' : 'p-1'}`">
                  <img
                    src="/images/box/box-2.png"
                    alt="box"
                    class="img-fluid"
                  >
                </div>

                <p class="mb-0 text-primary">
                  กล่องละ 58,000w
                </p>
              </div>
            </div>

            <div class="col-3 pl-50 pr-50 cx-box">
              <p class="x-poster m-0">
                1ชุด
              </p>

              <div
                class="card-box"
                :class="{ actived: getLast === 1 }"
                @click="(getLast = 1), (type = 2)"
              >
                <div :class="`${getLast === 1 ? 'p-75' : 'p-1'}`">
                  <img
                    src="/images/box/box-1.png"
                    alt="box"
                    class="img-fluid"
                  >
                </div>

                <p class="mb-0 text-primary">
                  กล่องละ 30,000w
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Box select 6b -->
      <div v-if="isActive === 2">
        <div class="row justify-content-center my-2">
          <div class="col-md-4 col-6 p-0">

            <!-- <div
              v-if="getLast !== 1"
              class="post-tail"
            >
              <p class="mb-0 text-white ">
                สุดคุ้ม
              </p>
              <h4 class="mb-0 mt-n25 text-white ">
                ลด {{ getLast === 10 ? '20%' : getLast === 5 ? '10%' : getLast === 2 ? '5%' : '' }}
              </h4>
            </div> -->

            <div class="card-box-xl">
              <img
                v-if="getLast !== 1"
                :src="`/images/sale/sale-${
                  getLast === 10
                    ? '20'
                    : getLast === 5
                      ? '10'
                      : getLast === 2
                        ? '5'
                        : ''
                }.svg`"
                alt=""
                class="img-sale"
              >

              <img
                :src="`/images/box/box-${getLast}-lg.png`"
                alt=""
                class="img-box"
              >
              <!-- <p class="x-poster">
                {{ getLast }}
              </p> -->

              <div class="point-btm">
                ได้รับ
                {{
                  getLast === 10
                    ? "160"
                    : getLast === 5
                      ? "70"
                      : getLast === 2
                        ? "20"
                        : getLast === 1
                          ? "5"
                          : ""
                }}
                พ้อยท์
              </div>
            </div>
          </div>
        </div>

        <div class="img-table">
          <img
            src="@/assets/images/newIcon/t-point.png"
            alt="t-point"
            class="img-fluid"
          >
        </div>

        <div class="row my-1">
          <div class="col-3 pl-50 pr-50 cx-box">
            <p class="x-poster m-0">
              10ชุด
            </p>

            <div
              class="card-box"
              :class="{ actived: getLast === 10 }"
              @click="(getLast = 10), (type = 0)"
            >
              <!-- <div class="post-tail d-flex justify-content-center">
                <p class="mb-0 mt-n25 text-white ">
                  -20%
                </p>
              </div> -->
              <img
                src="/images/sale/20per.svg"
                alt="20per"
                class="img-xs-sale"
              >

              <div :class="`${getLast === 10 ? 'p-75' : 'p-1'}`">
                <img
                  src="/images/box/box-10.png"
                  alt="box"
                  class="img-fluid"
                >
              </div>

              <p class="mb-0 text-primary">
                กล่องละ 240,000w
              </p>
            </div>
          </div>

          <div class="col-3 pl-50 pr-50 cx-box">
            <p class="x-poster m-0">
              5ชุด
            </p>

            <div
              class="card-box"
              :class="{ actived: getLast === 5 }"
              @click="(getLast = 5), (type = 0)"
            >
              <!-- <div class="post-tail d-flex justify-content-center">
                <p class="mb-0 mt-n25 text-white ">
                  -10%
                </p>
              </div> -->
              <img
                src="/images/sale/10per.svg"
                alt="10per"
                class="img-xs-sale"
              >

              <div :class="`${getLast === 5 ? 'p-75' : 'p-1'}`">
                <img
                  src="/images/box/box-5.png"
                  alt="box"
                  class="img-fluid"
                >
              </div>

              <p class="mb-0 text-primary">
                กล่องละ 135,000w
              </p>
            </div>
          </div>

          <div class="col-3 pl-50 pr-50 cx-box">
            <p class="x-poster m-0">
              2ชุด
            </p>

            <div
              class="card-box"
              :class="{ actived: getLast === 2 }"
              @click="(getLast = 2), (type = 1)"
            >
              <!-- <div class="post-tail d-flex justify-content-center">
                <p class="mb-0 mt-n25 text-white ">
                  -5%
                </p>
              </div> -->
              <img
                src="/images/sale/5per.svg"
                alt="5per"
                class="img-xs-sale"
              >

              <div :class="`${getLast === 2 ? 'p-75' : 'p-1'}`">
                <img
                  src="/images/box/box-2.png"
                  alt="box"
                  class="img-fluid"
                >
              </div>

              <p class="mb-0 text-primary">
                กล่องละ 58,000w
              </p>
            </div>
          </div>

          <div class="col-3 pl-50 pr-50 cx-box">
            <p class="x-poster m-0">
              1ชุด
            </p>

            <div
              class="card-box"
              :class="{ actived: getLast === 1 }"
              @click="(getLast = 1), (type = 2)"
            >
              <div :class="`${getLast === 1 ? 'p-75' : 'p-1'}`">
                <img
                  src="/images/box/box-1.png"
                  alt="box"
                  class="img-fluid"
                >
              </div>

              <p class="mb-0 text-primary">
                กล่องละ 30,000w
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Tab กล่องสุ่ม กล่องสุ่มสลาก -->
      <div class="tab-xs d-flex align-items-center">
        <div class="w-50 p-50">
          <div
            class="lot-wxs"
            :class="{ active: isActive === 1 }"
            @click="isActive = 1"
          >
            กล่องสุ่ม2ตัวท้าย
          </div>
        </div>
        <div class="w-50 p-50">
          <div
            class="lot-wxs"
            :class="{ active: isActive === 2 }"
            @click="isActive = 2"
          >
            กล่องสุ่มสลาก
          </div>
        </div>
      </div>

      <!-- Box select 2b -->
      <div v-if="isActive === 1">
        <!-- Box2b_10 lottery -->
        <div
          v-if="getLast === 10"
          class="row mt-1"
        >
          <!-- Box2b_1 -->
          <div
            v-for="(item, index) in box2b_10"
            :key="index"
            class="col-6 lotto-item p-50"
          >
            <div class="ctx-box">
              <div
                v-if="addedIds.includes(item._id)"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.57);
                  z-index: 9;
                "
              >
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>
              <div class="pad-txt p-25">
                <div class="text-center small text-secondary">
                  กล่อง 10ชุด ลำดับที่ {{ item.box_number }}
                </div>

                <div class="text-center">
                  <img
                    src="/images/box/box-10.png"
                    alt=""
                    height="110"
                  >
                </div>

                <div
                  class="mx-25 mb-25 md-mx-1 md-mb-50 d-flex justify-content-center"
                >
                  <button
                    class="bnn btn-add-cart-box mr-50"
                    @click="addCart(item, getLast)"
                  >
                    เพิ่มลงตะกร้า
                  </button>
                  <button
                    class="bnn btn-add-cart-point"
                    @click="buystep1(item)"
                  >
                    ใช้ Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Box2b_5 lottery -->
        <div
          v-if="getLast === 5"
          class="row mt-1"
        >
          <div
            v-for="(item, index) in box2b_5"
            :key="index"
            class="col-6 lotto-item p-50"
          >
            <div class="ctx-box">
              <div
                v-if="addedIds.includes(item._id)"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.57);
                  z-index: 9;
                "
              >
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>
              <div class="pad-txt p-25">
                <div class="text-center small text-secondary">
                  กล่อง 5ชุด ลำดับที่ {{ item.box_number }}
                </div>

                <div class="text-center">
                  <img
                    src="/images/box/box-5.png"
                    alt=""
                    height="110"
                  >
                </div>

                <div
                  class="mx-25 mb-25 md-mx-1 md-mb-50 d-flex justify-content-center"
                >
                  <button
                    class="bnn btn-add-cart-box mr-50"
                    @click="addCart(item, getLast)"
                  >
                    เพิ่มลงตะกร้า
                  </button>
                  <button
                    class="bnn btn-add-cart-point"
                    @click="buystep1(item)"
                  >
                    ใช้ Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Box2b_2 lottery -->
        <div
          v-if="getLast === 2"
          class="row mt-1"
        >
          <div
            v-for="(item, index) in box2b_2"
            :key="index"
            class="col-6 lotto-item p-50"
          >
            <div class="ctx-box">
              <div
                v-if="addedIds.includes(item._id)"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.57);
                  z-index: 9;
                "
              >
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>
              <div class="pad-txt p-25">
                <div class="text-center small text-secondary">
                  กล่อง 2ชุด ลำดับที่ {{ item.box_number }}
                </div>

                <div class="text-center">
                  <img
                    src="/images/box/box-2.png"
                    alt=""
                    height="110"
                  >
                </div>

                <div
                  class="mx-25 mb-25 md-mx-1 md-mb-50 d-flex justify-content-center"
                >
                  <button
                    class="bnn btn-add-cart-box mr-50"
                    @click="addCart(item, getLast)"
                  >
                    เพิ่มลงตะกร้า
                  </button>
                  <button
                    class="bnn btn-add-cart-point"
                    @click="buystep1(item)"
                  >
                    ใช้ Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Box2b_1 lottery -->
        <div
          v-if="getLast === 1"
          class="row mt-1"
        >
          <div
            v-for="(item, index) in box2b_1"
            :key="index"
            class="col-6 lotto-item p-50"
          >
            <div class="ctx-box">
              <div
                v-if="addedIds.includes(item._id)"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.57);
                  z-index: 9;
                "
              >
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>

              <div class="pad-txt p-25">
                <div class="text-center small text-secondary">
                  กล่อง 1ชุด ลำดับที่ {{ item.box_number }}
                </div>

                <div class="text-center">
                  <img
                    src="/images/box/box-1.png"
                    alt=""
                    height="110"
                  >
                </div>

                <div
                  class="mx-25 mb-25 md-mx-1 md-mb-50 d-flex justify-content-center"
                >
                  <button
                    class="bnn btn-add-cart-box mr-50"
                    @click="addCart(item, getLast)"
                  >
                    เพิ่มลงตะกร้า
                  </button>
                  <button
                    class="bnn btn-add-cart-point"
                    @click="buystep1(item)"
                  >
                    ใช้ Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Box select 6b -->
      <div v-if="isActive === 2">
        <!-- Box6_10 -->
        <div
          v-if="getLast === 10"
          class="row mt-1"
        >
          <div
            v-for="(item, index) in box6_10"
            :key="index"
            class="col-6 lotto-item p-50"
          >
            <div class="ctx-box">
              <div
                v-if="addedIds.includes(item._id)"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.57);
                  z-index: 9;
                "
              >
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>
              <div class="pad-txt p-25">
                <div class="text-center small text-secondary">
                  กล่อง 10ชุด ลำดับที่ {{ item.box_number }}
                </div>

                <div class="text-center">
                  <img
                    src="/images/box/box-10.png"
                    alt=""
                    height="110"
                  >
                </div>

                <div
                  class="mx-25 mb-25 md-mx-1 md-mb-50 d-flex justify-content-center"
                >
                  <button
                    class="bnn btn-add-cart-box mr-50"
                    @click="addCart(item, getLast)"
                  >
                    เพิ่มลงตะกร้า
                  </button>
                  <button
                    class="bnn btn-add-cart-point"
                    @click="buystep1(item)"
                  >
                    ใช้ Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Box6_5 -->
        <div
          v-if="getLast === 5"
          class="row mt-1"
        >
          <div
            v-for="(item, index) in box6_5"
            :key="index"
            class="col-6 lotto-item p-50"
          >
            <div class="ctx-box">
              <div
                v-if="addedIds.includes(item._id)"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.57);
                  z-index: 9;
                "
              >
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>
              <div class="pad-txt p-25">
                <div class="text-center small text-secondary">
                  กล่อง 5ชุด ลำดับที่ {{ item.box_number }}
                </div>

                <div class="text-center">
                  <img
                    src="/images/box/box-5.png"
                    alt=""
                    height="110"
                  >
                </div>

                <div
                  class="mx-25 mb-25 md-mx-1 md-mb-50 d-flex justify-content-center"
                >
                  <button
                    class="bnn btn-add-cart-box mr-50"
                    @click="addCart(item, getLast)"
                  >
                    เพิ่มลงตะกร้า
                  </button>
                  <button
                    class="bnn btn-add-cart-point"
                    @click="buystep1(item)"
                  >
                    ใช้ Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Box6_2 -->
        <div
          v-if="getLast === 2"
          class="row mt-1"
        >
          <div
            v-for="(item, index) in box6_2"
            :key="index"
            class="col-6 lotto-item p-50"
          >
            <div class="ctx-box">
              <div
                v-if="addedIds.includes(item._id)"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.57);
                  z-index: 9;
                "
              >
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>
              <div class="pad-txt p-25">
                <div class="text-center small text-secondary">
                  กล่อง 2ชุด ลำดับที่ {{ item.box_number }}
                </div>

                <div class="text-center">
                  <img
                    src="/images/box/box-2.png"
                    alt=""
                    height="110"
                  >
                </div>

                <div
                  class="mx-25 mb-25 md-mx-1 md-mb-50 d-flex justify-content-center"
                >
                  <button
                    class="bnn btn-add-cart-box mr-50"
                    @click="addCart(item, getLast)"
                  >
                    เพิ่มลงตะกร้า
                  </button>
                  <button
                    class="bnn btn-add-cart-point"
                    @click="buystep1(item)"
                  >
                    ใช้ Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Box6_1 -->
        <div
          v-if="getLast === 1"
          class="row mt-1"
        >
          <div
            v-for="(item, index) in box6_1"
            :key="index"
            class="col-6 lotto-item p-50"
          >
            <div class="ctx-box">
              <div
                v-if="addedIds.includes(item._id)"
                style="
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.57);
                  z-index: 9;
                "
              >
                <img
                  src="/images/box/added.png"
                  alt="cart-add"
                  class="ic-cart"
                >
              </div>

              <div class="pad-txt p-25">
                <div class="text-center small text-secondary">
                  กล่อง 1ชุด ลำดับที่ {{ item.box_number }}
                </div>

                <div class="text-center">
                  <img
                    src="/images/box/box-1.png"
                    alt=""
                    height="110"
                  >
                </div>

                <div
                  class="mx-25 mb-25 md-mx-1 md-mb-50 d-flex justify-content-center"
                >
                  <button
                    class="bnn btn-add-cart-box mr-50"
                    @click="addCart(item, getLast)"
                  >
                    เพิ่มลงตะกร้า
                  </button>
                  <button
                    class="bnn btn-add-cart-point"
                    @click="buystep1(item)"
                  >
                    ใช้ Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        ref="modal-confirm-buy"
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <h3 class="text-primary">
            ยืนยันการแลกรับกล่องสุ่ม {{ cshow }} ชุด
          </h3>
        </div>

        <div class="d-flex align-items-center justify-content-center my-2">
          <p class="mb-0 text-primary">
            ทั้งหมด
          </p>

          <input
            v-model="pshow"
            disabled
            type="number"
            class="point-txt mx-1"
          >

          <p class="mb-0 text-primary">
            Point
          </p>
        </div>

        <div class="text-center">
          <button
            class="btn-confirm"
            @click="confirmSubmit"
          >
            ยืนยัน
          </button>

          <button
            class="btn-del"
            @click="$refs['modal-confirm-buy'].hide()"
          >
            ยกเลิก
          </button>
        </div>
      </b-modal>
    </div>

    <ThisFooter
      :box6ss="box6s"
      :box2bss="box2bs"
    />
  </div>
</template>

<script>
import ThisFooter from '../components/ThisFooter.vue'

export default {
  name: 'Box',
  components: {
    ThisFooter,
  },
  data() {
    return {
      Loadmore: false,
      isActive: 1,
      getLast: 10,
      getLast6: 4,
      type: 0,
      box6_1: [],
      box6_2: [],
      box6_5: [],
      box6_10: [],
      box2b_1: [],
      box2b_2: [],
      box2b_5: [],
      box2b_10: [],
      addedIds: [],
      box6s: [],
      box2bs: [],
      usepoint: false,
      buybox: null,
      cshow: 0,
      pshow: 0,
    }
  },
  async mounted() {
    this.GetBox6_1()
    this.GetBox6_2()
    this.GetBox6_5()
    this.GetBox6_10()

    this.GetBox2b_1()
    this.GetBox2b_2()
    this.GetBox2b_5()
    this.GetBox2b_10()

    if (this.$route.query.type) {
      // this.knowby = 6
      this.usepoint = true
    }
    // console.log(this.box2b)
  },
  methods: {
    buystep1(item) {
      this.buybox = item
      this.cshow = item.count
      this.pshow = item.point
      this.$refs['modal-confirm-buy'].show()
    },
    confirmSubmit() {
      this.usePoint(this.buybox)
      this.$refs['modal-confirm-buy'].hide()
    },
    addCart(item) {
      const obj = {
        // eslint-disable-next-line no-underscore-dangle
        id: item._id,
      }
      this.$http
        .put('/lotto-suvarn/addBoxToMyCart', obj)
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          if (item.type === 2) {
            if (localStorage.getItem('box2b')) {
              const box2b = JSON.parse(localStorage.getItem('box2b'))
              box2b.unshift(item)
              localStorage.setItem('box2b', JSON.stringify(box2b))
            } else {
              const box2b = []
              box2b.unshift(item)
              localStorage.setItem('box2b', JSON.stringify(box2b))
            }
          } else if (item.type === 1) {
            if (localStorage.getItem('box6')) {
              const box6 = JSON.parse(localStorage.getItem('box6'))
              box6.unshift(item)
              localStorage.setItem('box6', JSON.stringify(box6))
            } else {
              const box6 = []
              box6.unshift(item)
              localStorage.setItem('box6', JSON.stringify(box6))
            }
          }
          // box6s
          this.box6s = JSON.parse(localStorage.getItem('box6'))
          // box2bs
          this.box2bs = JSON.parse(localStorage.getItem('box2b'))
          if (localStorage.getItem('boxall')) {
            const boxall = localStorage.getItem('boxall')
            localStorage.setItem('boxall', JSON.stringify(Number(boxall) + 1))
          } else {
            localStorage.setItem('boxall', JSON.stringify(1))
          }

          // eslint-disable-next-line no-underscore-dangle
          this.addedIds.push(item._id)

          const addedArray = this.addedIds.map(id => ({ _id: id }))
          localStorage.setItem('added', JSON.stringify(addedArray))

          const sump = localStorage.getItem('sumprice')
          const sum = Number(sump) + Number(item.price_discount)
          localStorage.setItem('sumprice', JSON.stringify(sum))
          // console.log(ress.data)
          // this.Success('เพิ่มสลากลงตะกร้าสำเร็จ')
        })
        .catch(error => {
          this.box2b = []
          this.GetBox6()
          this.GetBox6_4()
          this.GetBox2b()
          this.SwalError(error.response.data.message)
        })
    },
    usePoint(item) {
      this.Loadmore = true
      const obj = {
        // eslint-disable-next-line no-underscore-dangle
        id: item._id,
      }
      this.$http
        .post('/lotto-suvarn/BuyLuckyBox/UsePoint', obj)
        .then(response => {
          this.BoxSuccess(response.data.message)
          this.GetBox6_1()
          this.GetBox6_2()
          this.GetBox6_5()
          this.GetBox6_10()

          this.GetBox2b_1()
          this.GetBox2b_2()
          this.GetBox2b_5()
          this.GetBox2b_10()
          this.Loadmore = false
          this.buybox = null
          this.cshow = 0
          this.pshow = 0
        })
        .catch(error => {
          this.GetBox6_1()
          this.GetBox6_2()
          this.GetBox6_5()
          this.GetBox6_10()

          this.GetBox2b_1()
          this.GetBox2b_2()
          this.GetBox2b_5()
          this.GetBox2b_10()
          this.SwalError(error.response.data.message)
          this.Loadmore = false
          this.buybox = null
          this.cshow = 0
          this.pshow = 0
        })
    },
    GetBox6_1() {
      this.$http.get('/lotto-suvarn/LuckyBox/num6/count1').then(ress => {
        this.box6_1 = ress.data.lottery
      })
    },
    GetBox6_2() {
      this.$http.get('/lotto-suvarn/LuckyBox/num6/count2').then(ress => {
        this.box6_2 = ress.data.lottery
      })
    },
    GetBox6_5() {
      this.$http.get('/lotto-suvarn/LuckyBox/num6/count5').then(ress => {
        this.box6_5 = ress.data.lottery
      })
    },
    GetBox6_10() {
      this.$http.get('/lotto-suvarn/LuckyBox/num6/count10').then(ress => {
        this.box6_10 = ress.data.lottery
        // console.log(ress.data)
      })
    },
    GetBox2b_1() {
      this.$http.get('/lotto-suvarn/LuckyBox/num2b/count1').then(ress => {
        this.box2b_1 = ress.data.lottery
      })
    },
    GetBox2b_2() {
      this.$http.get('/lotto-suvarn/LuckyBox/num2b/count2').then(ress => {
        this.box2b_2 = ress.data.lottery
      })
    },
    GetBox2b_5() {
      this.$http.get('/lotto-suvarn/LuckyBox/num2b/count5').then(ress => {
        this.box2b_5 = ress.data.lottery
      })
    },
    GetBox2b_10() {
      this.Loadmore = true
      this.$http.get('/lotto-suvarn/LuckyBox/num2b/count10').then(ress => {
        this.box2b_10 = ress.data.lottery
        this.Loadmore = false
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ลงตะกร้าสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    BoxSuccess(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
